import { useState, useEffect } from 'react'
import { getCoursesPaginated } from '../../api/drupalAPI'
import { Course } from '@icofcv/common';
import contentUtils from '../../lib/contentUtils'
import { useNavigate } from "react-router-dom";
import Loader from '../spinner/Loader';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { getItemsPerPage } from '../../api/drupalAPI';
import { setDetailNavigation } from '../../actions/configNavigation'
import { HOME_MODULE } from '../../constants/modules'
import { useStore } from 'react-redux'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import type { RootState } from '../../store/index'
import statusTypeId from './StatusTypeId'
import "./FormationList.css"

export const FormationList = () => {

    const store = useStore();
    const [courseList, setCourseList] = useState<Course[]>([]);
    const [searchCourse, setSearchCourse] = useState('');
    const [courseSearchType, setCourseSearchType] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [currentPageCourse, setCurrentPageCourse] = useState(0);
    const [viewMoreCourseDataDisabled, setViewMoreCourseDataDisabled] = useState(false);
    const session = useSelector((state: RootState) => state.session);

    let courseVisibleCount = 0;

    const fetchCourseList = async (filter) => {
        setIsLoading(true);
        getCoursesPaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMoreCourseDataDisabled(false);

                } else {
                    setViewMoreCourseDataDisabled(true);
                }

                var list: Course[] = [];
                if (filter.page == 0) {
                    list = response;
                } else {
                    list = [...courseList, ...response];
                }
                setCourseList(list);
                console.log(list);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const handleShowMoreData = () => {
        setCurrentPageCourse(currentPageCourse + 1);
        var filter = {
            page: currentPageCourse + 1,
            search: searchCourse,
            searchType: courseSearchType,

        };
        fetchCourseList(filter);
    }

    //funcion cambio de filtro    
    const handleFilterChanged = (id, value) => {
        setCurrentPageCourse(0);
        switch (id) {
            case 'typeId': setCourseSearchType(value); break;
        }
    }

    //funcion filtrado campos 
    const handleFilterButtonClicked = () => {
        const filter = {
            page: currentPageCourse,
            search: searchCourse,
            searchType: courseSearchType,
        };
        fetchCourseList(filter);
    }

    //reiniciar busqueda
    const handleRestartButtonClicked = () => {
        setCurrentPageCourse(0);
        setCourseSearchType('');
        const filter = {
            page: 0,
            search: searchCourse,
            searchType: '',
        };
        fetchCourseList(filter);
    }

    //funcion filtro principal  
    const handleChange = e => {
        setCurrentPageCourse(0);
        setSearchCourse(e.target.value);
        setTimeout(() => {
            const filter = {
                page: 0,
                search: e.target.value,
                searchType: courseSearchType,
            };
            fetchCourseList(filter);
        }, 800);
    }

    /// abrir y cerrar filtro 
    const [showFilter, setShowFilter] = useState(false);
    const openFilter = () => setShowFilter(true);
    const closeFilter = () => setShowFilter(false);

    function handleAddToDetail(id) {
        console.log('ID', id)
        navigate('formationdetail?id=' + id);
    }

    useEffect(() => {
        store.dispatch(setDetailNavigation(HOME_MODULE, 'Actualidad!'));
        const filter = {
            page: 0,
            search: searchCourse,
            searchType: '',
        };
        fetchCourseList(filter)
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-40 pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/college">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Formación</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3 content-card">
                    <Link className="w-40 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Formación</span>
                    </Link>
                    <div className="flex items-center md:items-baseline justify-start gap-2">
                        <select onChange={(e) => handleFilterChanged('typeId', e.target.value)} value={courseSearchType} className="w-40 md:w-56 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 hidden md:block w-full p-2">
                            <option value={""}>Seleccionar tipo</option>
                            <option value={"1"}>Cursos del ICOFCV</option>
                            <option value={"2"}>Cursos Externos</option>
                            <option value={"3"}>Seminarios del ICOFCV</option>
                            <option value={"curso_congreso"}>Curso Congreso</option>
                            <option value={"oficial"}>Oficial</option>
                            <option value={" otra_formacion"}>Otra Formación</option>
                            <option value={" titulo_propio"}>Título Propio</option>
                            <option value={" otros_colegios"}>Cursos de Otros Colegios</option>
                        </select>
                        <input
                            type="search"
                            value={searchCourse}
                            placeholder="Buscar..."
                            className="md:w-56 w-72 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block p-2 mb-2"
                            aria-label="Search"
                            onChange={handleChange}
                        />
                        <div className="hidden md:flex items-center gap-2 mb-3">
                            <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleFilterButtonClicked}>Buscar</button>
                            <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleRestartButtonClicked}>Limpiar</button>
                        </div>
                        <div className="mb-2 flex items-center">
                            {/* Botón filtros sólo para móvil */}
                            {showFilter === false ?
                                <button onClick={openFilter} type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center block md:hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                    </svg>
                                </button>
                                :
                                <button onClick={closeFilter} type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center block md:hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                    </svg>
                                </button>
                            }
                        </div>
                    </div>
                    {showFilter === true ?
                        <>
                            <div className="md:hidden items-end justify-between grid grid-cols-1 gap-2 p-2">
                                <div className="relative z-0 w-full group mb-2">
                                    <select onChange={(e) => handleFilterChanged('typeId', e.target.value)} value={courseSearchType} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-56 p-2">
                                        <option value={""}>Seleccionar tipo</option>
                                        <option value={"1"}>Cursos del ICOFCV</option>
                                        <option value={"2"}>Cursos Externos</option>
                                        <option value={"3"}>Seminarios del ICOFCV</option>
                                        <option value={"curso_congreso"}>Curso Congreso</option>
                                        <option value={"oficial"}>Oficial</option>
                                        <option value={" otra_formacion"}>Otra Formación</option>
                                        <option value={" titulo_propio"}>Título Propio</option>
                                        <option value={" otros_colegios"}>Cursos de Otros Colegios</option>
                                    </select>
                                    <span className="mt-2 text-xs text-red-600"></span>
                                </div>
                                <div className="hidden md:flex items-center gap-2">
                                    <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" onClick={handleFilterButtonClicked} type="button" >Filtrar</button>
                                    <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" onClick={handleRestartButtonClicked} type="button" >Limpiar</button>
                                </div>
                            </div>
                            <div className="flex md:hidden items-center gap-2">
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" onClick={handleFilterButtonClicked} type="button">Filtrar</button>
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" onClick={handleRestartButtonClicked} type="button">Limpiar</button>
                            </div>
                        </>
                        : <div></div>
                    }

                    {isLoading && <Loader />}
                    {
                        courseList.map(course => (
                            <>
                                {course.visibilityStartDate < new Date().toISOString().slice(0, -8) && course.visibilityEndDate > new Date().toISOString().slice(0, -8) && course.status !== 1 ?
                                    <div key={course.id} className="bg-white px-2 py-3 md:h-56 border-b rounded-0">
                                        <span className="hidden">{++courseVisibleCount}</span>
                                        <div className="flex items-top flex-col md:flex-row md:items-center gap-2 grid grid-cols-12">
                                            <div className="col-span-12 md:col-span-2">
                                                <button onClick={() => handleAddToDetail(course.id)} className="text-left overflow-hidden pb-2 w-100" >
                                                    <img className="h-48 w-full object-cover object-center mx-auto" src={contentUtils.getLargeImageUrl(course.imagePath)} alt="NewsImage" />
                                                </button>
                                            </div>
                                            <div className="ml-2 md:h-40 col-span-12 md:col-span-10">
                                                <div className="flex justify-start flex-wrap items-center my-2 gap-2">
                                                    <button onClick={() => handleAddToDetail(course.id)} className="" >
                                                        <span className="text-lg text-neutral-800 font-medium news-title text-left">{course.title}</span>
                                                    </button>
                                                    <div className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">{statusTypeId(course.typeId)}</div>
                                                    {course.inscriptionStartDate < new Date().toISOString().slice(0, -8) && course.inscriptionEndDate > new Date().toISOString().slice(0, -8) ?
                                                        <div></div>
                                                        :
                                                        <div className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">Fuera plazo inscripción</div>
                                                    }
                                                    {course.soldOutPlaces && course.inscriptionStartDate < new Date().toISOString().slice(0, -8) && course.inscriptionEndDate > new Date().toISOString().slice(0, -8) ?
                                                        <div className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">Plazas agotadas</div>
                                                        : null}

                                                </div>
                                                <div className="flex justify-start flex-wrap items-center my-2 gap-2">
                                                    <p className="text-teal-600 text-sm">{format(new Date(course.creationDate), 'EEE dd/MM/Y', { locale: es }).toUpperCase()}</p>
                                                </div>
                                                {/* <div className="description" dangerouslySetInnerHTML={{ __html: course.description ? course.description : '' }}></div> */}
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </>
                        ))
                    }
                {/* Contenido vacío */}
                {courseVisibleCount === 0 ?
                    <div className="my-4">
                        <div className="flex flex-col items-center py-8">
                            <div className="mx-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school-off" width="44" height="44" viewBox="0 0 24 24" stroke-width="1" stroke="#9ca3af" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M22 9l-10 -4l-2.136 .854m-2.864 1.146l-5 2l10 4l.697 -.279m2.878 -1.151l6.425 -2.57v6" />
                                    <path d="M6 10.6v5.4c0 1.657 2.686 3 6 3c2.334 0 4.357 -.666 5.35 -1.64m.65 -3.36v-3.4" />
                                    <path d="M3 3l18 18" />
                                </svg>
                            </div>
                            <h3 className="font-medium">No hay cursos</h3>
                            <p className="text-neutral-500 text-sm">En estos momentos no hay cursos de formación disponibles</p>
                        </div>
                    </div>
                :
                null}  

                </div>                  
                   
                {viewMoreCourseDataDisabled === false ?
                    <div className="flex items-center justify-end mx-3">
                        <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                    </div>
                    :
                    <div></div>
                }
            </div>
        </>
    )
}