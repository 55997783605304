import { useState } from "react";
import { useSelector } from "react-redux";
import type { RootState } from '../../store/index'
import { Formik, Form, Field, ErrorMessage } from "formik";
import ScrollToError from "./ScrollToError";
import validationSchema from "../../validation-form-collegiate/validationSchema";
import { PictureInput } from './PictureInput';
import { registerRequest } from '../../api/icofcvAPI';
import base64Utils from '../../lib/base64Utils';
import contentUtils from '../../lib/contentUtils';
import { ModalConfirmation } from "./ModalConfirmation";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { toast } from 'react-toastify';
import Loader from '../spinner/Loader';
import jquery from 'jquery';
import { Link } from "react-router-dom";

interface FormValues {
    //validacion datos personales
    firstName: string;
    lastName: string;
    dateofbirth: string;
    address: string;
    cp: String;
    town: string;
    province: string;
    dni: String;
    sex: string;
    mobilephone: String;
    otherphone: String;
    email: string;
    ///validacion datos profesionales
    workCenter: string;
    addressCenter: string;
    cpCenter: String;
    townCenter: string;
    provinceCenter: String;
    phoneCenter: String;
    //validacion documentacion
    title: null;
    photoCara: null;
    photoDni: null;
    registration: null;
    ///validacion domiciliacion bancaria
    accountNumber: String;
    BicNumber: String;
    //validacion checkbox
    checkbox1: boolean;
    checkbox2: boolean;
    checkbox3: boolean;
    checkbox4: boolean;
    checkbox5: boolean;
    checkbox6: boolean;
    checkbox7: boolean;
}

export const RegistrationRequest = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const session = useSelector((state: RootState) => state.session);
    const $: JQueryStatic = jquery;

    function scrollBlock(){
         $("#root").css("overflow-y","hidden");
    }

    function scrollActive(){
        $("#root").css("overflow-y","auto");
   }

// enableScroll(scrollTarget)

    ///funcionalidad modal
    const [values, setValues] = useState({});
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true)
        scrollBlock()
        // disableScroll(scrollTarget)
    }
    const closeModal = () => {
        setShowModal(false)
        scrollActive()
    }

    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const openModalConfirmation = () => setShowModalConfirmation(true);
    

    const continueModalConfirmation = () => {
        console.log(values);
        let images = [];
        convertImagesToBase64(values, images, () => sendEmail(values, images));
    };
    const closeModalConfirmation = () => setShowModalConfirmation(false);

    /////funcionalidad Acordeones
    const [showAccordeon1, setShowAccordeon1] = useState(false)
    const [showAccordeon2, setShowAccordeon2] = useState(false)
    const [showAccordeon3, setShowAccordeon3] = useState(false)
    const [showAccordeon4, setShowAccordeon4] = useState(false)

    function handleCloseConfirmation() {
        setShowModalConfirmation(false);
        return setShowModal(true);
    }

    function handleShowConfirmation() {
        setShowModalConfirmation(true);
        return setShowModal(false);
    }

    const urlForm1 = "https://www.colfisiocv.com/cuota-semestral?width=500&height=500&iframe=true";
    const urlForm2 = "https://www.colfisiocv.com/sites/default/files/solicitud_baja_2019.pdf";
    const urlForm3 = "https://www.colfisiocv.com/obligaciones";
    const urlForm4 = "https://www.colfisiocv.com/servicios";
    const urlForm5 = "https://www.colfisiocv.com/lopd-datos?width=600&height=400&iframe=true";
    const urlForm6 = "https://www.colfisiocv.com/cuota-inscripcion?width=500&height=500&iframe=true";

    const openLink = () => contentUtils.openExternalUrl(urlForm1);
    const openLink2 = () => contentUtils.openExternalUrl(urlForm2);
    const openLink3 = () => contentUtils.openExternalUrl(urlForm3);
    const openLink4 = () => contentUtils.openExternalUrl(urlForm4);
    const openLink5 = () => contentUtils.openExternalUrl(urlForm5);
    const openLink6 = () => contentUtils.openExternalUrl(urlForm6);

    const initialValues: FormValues = {
        //validacion datos personales
        firstName: "",
        lastName: "",
        dateofbirth: "",
        address: "",
        cp: "",
        town: "",
        province: "",
        dni: "",
        sex: "",
        mobilephone: "",
        otherphone: "",
        email: "",

        //validacion datos profesionales
        workCenter: "",
        addressCenter: "",
        cpCenter: "",
        townCenter: "",
        provinceCenter: "",
        phoneCenter: "",
        //validacion documentacion
        title: null,
        photoCara: null,
        photoDni: null,
        registration: null,
        //validacion domiciliacion bancaria    
        accountNumber: "",
        BicNumber: "",
        //validacion checkbox
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,
    };

    const onSubmit = (values: FormValues) => {
        setValues(values);
        setShowModalConfirmation(true);

    }

    function sendEmail(values, images) {
        setIsLoading(true)
        var data = {
            firstname: values.firstName,
            lastname: values.lastName,
            birthday: format(new Date(values.dateofbirth), 'dd/MM/yyyy', { locale: es }),
            address: values.address,
            cp: values.cp,
            town: values.town,
            province: values.province,
            dni: values.dni,
            sex: values.sex,
            mobilePhone: values.mobilephone,
            otherPhone: values.otherphone,
            email: values.email,
            workCenter: values.workCenter,
            addressCenter: values.addressCenter,
            cpCenter: values.cpCenter,
            townCenter: values.townCenter,
            provinceCenter: values.provinceCenter,
            phoneCenter: values.phoneCenter,
            accountNumber: values.accountNumber,
            bicNumber: values.BicNumber,
            files: images
        };

        registerRequest(data)
            .then((response) => {
                setShowModalConfirmation(false);
                setShowModal(false);
                toast.success('Solicitud enviada');
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    function convertImagesToBase64(values, images, cb) {
        convertImageToBase64(values.title, images, () => {
            convertImageToBase64(values.photoCara, images, () => {
                convertImageToBase64(values.photoDni, images, () => {
                    convertImageToBase64(values.registration, images, () => {
                        cb();
                    });
                });
            });
        });
    }

    function convertImageToBase64(files, images, cb) {
        if (!files || files.length == 0) {
            return;
        }
        base64Utils.convertToBase64(files[0], (filename, path) => {
            var img = {
                filename: filename,
                path: path
            };
            images.push(img);
            cb();
        });
    }

    return (
        <>
           {!session.isLoggedIn ?
           <>
            <button className="bg-white items-center justify-between rounded-lg h-14 p-4 w-100 mt-3 border border-gray-400 hidden md:flex hover:text-teal-800" type="button" onClick={openModal}>Colegiarse
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#231F20" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="9 6 15 12 9 18" />
                </svg>
            </button>
            </>
            :null}
            {/* Card solo movil */}
            <div className="card w-100 bg-base-100 md:hidden bg-white px-2 py-3 action-card">
                <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-certificate mx-2 h-6 w-6 stroke-neutral-800" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="15" cy="15" r="3" />
                        <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
                        <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
                        <line x1="6" y1="9" x2="18" y2="9" />
                        <line x1="6" y1="12" x2="9" y2="12" />
                        <line x1="6" y1="15" x2="8" y2="15" />
                    </svg>
                    <span>Solicitudes</span>
                </h1>
                <div className="card-body p-1">
                {!session.isLoggedIn ?
                    <div>
                        <h3 className="font-medium">SOLICITUD DE ALTA ONLINE COMO EJERCIENTE</h3>
                        <p className="my-2">Inicia los trámites de alta, en la figura colegial de ejerciente, de forma completamente online sin necesidad de personarte en las Sedes del Colegio.</p>
                        <div className="card-actions flex justify-center">
                            <button type="button" className="btn bg-teal-600 hover:bg-teal-800 active:bg-teal-600 text-white text-base px-8 py-2 mt-2 rounded" onClick={openModal}>Solicitar el alta online</button>
                        </div>
                    </div>
                    :null}
                    <div className="mt-3">
                        <p className="my-2">Para cualquier otra consulta ponte en contacto con nosotros rellenando el formulario.</p>
                        <div className="card-actions flex justify-center">
                        <Link to='/contactform' className="btn bg-teal-600 hover:bg-teal-800 active:bg-teal-600 text-white text-base px-8 py-2 mt-2 rounded">Formulario de contacto</Link>
                        </div>
                    </div>
                    
                </div>
            </div>

            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
                            <Form>
                                <div className="relative p-2 w-full max-w-5xl h-screen z-50 md:h-auto">
                                    {/*content*/}
                                    <div className="relative top-9 md:top-0 bg-white rounded-lg shadow">
                                        {/*header*/}
                                        <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                                            <div>
                                                <h1 className="text-lg font-medium">Formulario colegiarse</h1>
                                                <span className="text-xs font-medium text-red-500">Solo para nuevas altas ejercientes, no válido altas por traslado ni reincorporaciones.</span>
                                            </div>
                                            <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                                onClick={closeModal}>
                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        {/*body*/}
                                        <div className="relative px-3 py-3 flex-auto overflow-y-auto modal-body" style={{maxHeight: 'calc(100vh - 230px)', }}>
                                            <div id="accordion-collapse" data-accordion="collapse">
                                                {/* Item */}
                                                <h2 id="accordion-collapse-heading-1">
                                                    {showAccordeon1 === false ?
                                                        <button id="accordion-collapse-heading-button-1" onClick={() => setShowAccordeon1(true)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                            <span>Datos personales</span>
                                                            <svg data-accordion-icon className="w-6 h-6 rotate-360deg shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                            </svg>
                                                        </button>
                                                        :
                                                        <button id="accordion-collapse-heading-button-1" onClick={() => setShowAccordeon1(false)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                            <span>Datos personales</span>
                                                            <svg data-accordion-icon className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                            </svg>
                                                        </button>
                                                    }
                                                </h2>
                                                <div id="accordion-collapse-body-1" className={`${showAccordeon1 === false ? 'hidden' : 'hidden:block'} border-b pb-4`} aria-labelledby="accordion-collapse-heading-1">

                                                    <div className="grid md:grid-cols-2 md:gap-4">
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="firstName" className="block mb-2 text-xs font-medium text-stone-600">Nombre*</label>
                                                            <Field name="firstName" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="firstName" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="lastName" className="block mb-2 text-xs font-medium text-stone-600">Apellidos*</label>
                                                            <Field name="lastName" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="lastName" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="dateofbirth" className="block mb-2 text-xs font-medium text-stone-600">Fecha nacimiento*</label>
                                                            <Field name="dateofbirth" type="date" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="dateofbirth" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="address" className="block mb-2 text-xs font-medium text-stone-600">Dirección*</label>
                                                            <Field name="address" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="address" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="cp" className="block mb-2 text-xs font-medium text-stone-600">Código Postal*</label>
                                                            <Field name="cp" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="cp" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="town" className="block mb-2 text-xs font-medium text-stone-600">Población*</label>
                                                            <Field name="town" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="town" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="province" className="block mb-2 text-xs font-medium text-stone-600">Provincia*</label>
                                                            <Field name="province" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1">
                                                                <option value={''}>Seleccionar</option>
                                                                <option>Alicante</option>
                                                                <option>Castellón</option>
                                                                <option>Valencia</option>
                                                            </Field>
                                                            <ErrorMessage name="province" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="dni" className="block mb-2 text-xs font-medium text-stone-600">DNI/NIE*</label>
                                                            <Field name="dni" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="dni" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="sex" className="block mb-2 text-xs font-medium text-stone-600">Sexo*</label>
                                                            <Field name="sex" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1">
                                                                <option value={''}>Seleccionar</option>
                                                                <option>Hombre</option>
                                                                <option>Mujer</option>
                                                                <option>No procede</option>
                                                            </Field>
                                                            <ErrorMessage name="sex" component="span" className="mt-2 text-xs text-red-600" data-accordion="#accordion-collapse-body-1" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="mobilephone" className="block mb-2 text-xs font-medium text-stone-600">Teléfono móvil*</label>
                                                            <Field name="mobilephone" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="mobilephone" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="otherphone" className="block mb-2 text-xs font-medium text-stone-600">Teléfono fijo o alternativo*</label>
                                                            <Field name="otherphone" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="otherphone" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="email" className="block mb-2 text-xs font-medium text-stone-600">Email*</label>
                                                            <Field name="email" type="mail" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                            <ErrorMessage name="email" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Item */}
                                                <h2 id="accordion-collapse-heading-2">
                                                    {showAccordeon2 === false ?
                                                        <button id="accordion-collapse-heading-button-2" onClick={() => setShowAccordeon2(true)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-2" aria-expanded="true" aria-controls="accordion-collapse-body-2">
                                                            <span>Datos profesionales</span>
                                                            <svg data-accordion-icon className="w-6 h-6 rotate-360deg shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                                            </svg>
                                                        </button>
                                                        :
                                                        <button id="accordion-collapse-heading-button-2" onClick={() => setShowAccordeon2(false)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-2" aria-expanded="true" aria-controls="accordion-collapse-body-2">
                                                            <span>Datos profesionales</span>
                                                            <svg data-accordion-icon className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                                            </svg>
                                                        </button>
                                                    }
                                                </h2>

                                                <div id="accordion-collapse-body-2" className={`${showAccordeon2 === false ? 'hidden' : 'hidden:block'} border-b pb-4`} aria-labelledby="accordion-collapse-heading-2">

                                                    <div className="grid md:grid-cols-2 md:gap-4">
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="workCenter" className="block mb-2 text-xs font-medium text-stone-600">Centro de trabajo</label>
                                                            <Field name="workCenter" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                            <ErrorMessage name="workCenter" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="addressCenter" className="block mb-2 text-xs font-medium text-stone-600">Dirección</label>
                                                            <Field name="addressCenter" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                            <ErrorMessage name="addressCenter" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="cpCenter" className="block mb-2 text-xs font-medium text-stone-600">Código Postal</label>
                                                            <Field name="cpCenter" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                            <ErrorMessage name="cpCenter" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="townCenter" className="block mb-2 text-xs font-medium text-stone-600">Población</label>
                                                            <Field name="townCenter" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                            <ErrorMessage name="townCenter" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="provinceCenter" className="block mb-2 text-xs font-medium text-stone-600">Provincia</label>
                                                            <Field name="provinceCenter" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                            <ErrorMessage name="provinceCenter" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="phoneCenter" className="block mb-2 text-xs font-medium text-stone-600">Teléfono</label>
                                                            <Field name="phoneCenter" type="tel" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                            <ErrorMessage name="phoneCenter" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Item */}
                                                <h2 id="accordion-collapse-heading-3">
                                                    {showAccordeon3 === false ?
                                                        <button id="accordion-collapse-heading-button-3" onClick={() => setShowAccordeon3(true)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-3" aria-expanded="true" aria-controls="accordion-collapse-body-3">
                                                            <span>Documentación necesaria</span>
                                                            <svg data-accordion-icon className="w-6 h-6 rotate-360deg shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                            </svg>
                                                        </button>
                                                        :
                                                        <button id="accordion-collapse-heading-button-3" onClick={() => setShowAccordeon3(false)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-3" aria-expanded="true" aria-controls="accordion-collapse-body-3">
                                                            <span>Documentación necesaria</span>
                                                            <svg data-accordion-icon className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                            </svg>
                                                        </button>
                                                    }
                                                </h2>
                                                <div id="accordion-collapse-body-3" className={`${showAccordeon3 === false ? 'hidden' : 'hidden:block'} border-b pb-4`} aria-labelledby="accordion-collapse-heading-3">
                                                    <p className="my-2 text-xs text-neutral-500">Junto con su solicitud de alta es necesario que aporte la siguiente documentación:</p>
                                                    <div className="relative">
                                                        <label htmlFor="title" className="block text-neutral-500 font-medium text-sm">Adjuntar título*</label>
                                                        <Field name="title" component={PictureInput} className="block w-full text-xs text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer my-2" id="file" type="file" data-accordion="accordion-collapse-body-3" />
                                                        <ErrorMessage name="title" component="span" className="mt-2 text-xs text-red-600" />
                                                        <p className="my-2 text-xs text-neutral-500">Adjuntar título Oficial de Fisioterapia (Diplomatura o Graduado)</p>
                                                        <p className="my-2 text-xs text-neutral-500">Los archivos deben ser menores que <b>50MB</b>. Tipos de archivo permitidos: <b>.gif .jpg .jpeg .png .pdf</b>.</p>
                                                    </div>
                                                    <div className="relative">
                                                        <label htmlFor="photoCara" className="block text-neutral-500 font-medium text-sm">Fotografía*</label>
                                                        <Field name="photoCara" component={PictureInput} className="block w-full text-xs text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer my-2" id="" type="file" data-accordion="accordion-collapse-body-3" />
                                                        <ErrorMessage name="photoCara" component="span" className="mt-2 text-xs text-red-600" />
                                                        <p className="my-2 text-xs text-neutral-500">Los archivos deben ser menores que <b>10MB</b>. Tipos de archivo permitidos: <b>.gif .jpg .jpeg .png .pdf</b>.</p>
                                                    </div>
                                                    <div className="relative">
                                                        <label htmlFor="photoDni" className="block text-neutral-500 font-medium text-sm">Fotocopia DNI/NIE*</label>
                                                        <Field name="photoDni" component={PictureInput} className="block w-full text-xs text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer my-2" id="" type="file" data-accordion="accordion-collapse-body-3" />
                                                        <ErrorMessage name="photoDni" component="span" className="mt-2 text-xs text-red-600" />
                                                        <p className="my-2 text-xs text-neutral-500">Los archivos deben ser menores que <b>10MB</b>. Tipos de archivo permitidos: <b>.gif .jpg .jpeg .png .pdf</b>.</p>
                                                    </div>
                                                    <div className="relative">
                                                        <label htmlFor="registration" className="block text-neutral-500 font-medium text-sm">Justificante Cuota Inscripción*</label>
                                                        <Field name="registration" component={PictureInput} className="block w-full text-xs text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer my-2" id="" type="file" data-accordion="accordion-collapse-body-3" />
                                                        <ErrorMessage name="registration" component="span" className="mt-2 text-xs text-red-600" />
                                                        <p className="my-2 text-xs text-neutral-500">Revisa la <a onClick={openLink6} className="text-teal-600 underline">Cuota de Inscripción</a></p>
                                                        <p className="my-2 text-xs text-neutral-500">Los archivos deben ser menores que <b>10MB</b>. Tipos de archivo permitidos: <b>.gif .jpg .jpeg .png .pdf</b>.</p>
                                                    </div>
                                                </div>

                                                {/* Item */}
                                                <h2 id="accordion-collapse-heading-4">
                                                    {showAccordeon4 === false ?
                                                        <button id="accordion-collapse-heading-button-4" onClick={() => setShowAccordeon4(true)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-" aria-expanded="true" aria-controls="accordion-collapse-body-4">
                                                            <span>Domiciliación bancaria</span>
                                                            <svg data-accordion-icon className="w-6 h-6 rotate-360deg shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                            </svg>
                                                        </button>
                                                        :
                                                        <button id="accordion-collapse-heading-button-4" onClick={() => setShowAccordeon4(false)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-" aria-expanded="true" aria-controls="accordion-collapse-body-4">
                                                            <span>Domiciliación bancaria</span>
                                                            <svg data-accordion-icon className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                            </svg>

                                                        </button>
                                                    }
                                                </h2>

                                                <div id="accordion-collapse-body-4" className={`${showAccordeon4 === false ? 'hidden' : 'hidden:block'} border-b pb-4`} aria-labelledby="accordion-collapse-heading-4">
                                                    <div className="grid md:gap-4">
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="accountNumber" className="block mb-2 text-xs font-medium text-stone-600">Nº Cuenta-IBAN*</label>
                                                            <p className="my-2 text-xs text-neutral-500">Incluir los 24 dígitos sin espacios y sin guiones de separación</p>
                                                            <Field name="accountNumber" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-4" />
                                                            <ErrorMessage name="accountNumber" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                        <div className="relative z-0 w-full group mb-2">
                                                            <label htmlFor="BicNumber" className="block mb-2 text-xs font-medium text-stone-600">BIC de la Entidad*</label>
                                                            <Field name="BicNumber" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2" data-accordion="accordion-collapse-body-4" />
                                                            <ErrorMessage name="BicNumber" component="span" className="mt-2 text-xs text-red-600" />
                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Checkboxes */}
                                                <div className="border-t pt-4">
                                                    <div className="flex flex-col items-start mb-2">
                                                        <div className="flex flex-column items-top">
                                                            <div className="flex items-start">
                                                                <Field name="checkbox1" id="default-checkbox" type="checkbox" className="w-4 h-4 text-teal-600 bg-gray-100 rounded-sm border-gray-300" />
                                                                <label htmlFor="checkbox1" className="ml-2 text-xs text-neutral-500">Me comprometo a informar a mi entidad bancaria de que atiendan las cuotas semestrales del Colegio Oficial de Fisioterapeutas de la Comunidad Valenciana (dos al año).</label>
                                                            </div>
                                                            <ErrorMessage name="checkbox1" component="span" className="mt-2 ml-6 text-xs text-red-600" />
                                                        </div>
                                                        <p className="my-2 text-xs text-neutral-500 pl-6">Consultar las <a onClick={openLink} className="text-teal-600 underline">Cuotas semestrales</a></p>
                                                    </div>
                                                    <div className="flex flex-col items-start mb-2">
                                                        <div className="flex flex-column items-top">
                                                            <div className="flex items-start">
                                                                <Field name="checkbox2" id="default-checkbox" type="checkbox" className="w-4 h-4 text-teal-600 bg-gray-100 rounded-sm border-gray-300" />
                                                                <label htmlFor="checkbox2" className="ml-2 text-xs text-neutral-500">Me comprometo a comunicar la baja POR ESCRITO adjuntando el formulario de solicitud a tal efecto y el carné de colegiado; así como también, atender a los pagos que hubiera pendientes en el momento de producirse esta</label>
                                                            </div>
                                                            <ErrorMessage name="checkbox2" component="span" className="mt-2 ml-6 text-xs text-red-600" />
                                                        </div>
                                                        <p className="my-2 text-xs text-neutral-500 pl-6">Descarga la solicitud de <a onClick={openLink2} className="text-teal-600 underline">Baja Colegial</a></p>
                                                    </div>
                                                    <div className="flex flex-col items-start mb-2">
                                                        <div className="flex flex-column items-top">
                                                            <div className="flex items-start">
                                                                <Field name="checkbox3" id="default-checkbox" type="checkbox" className="w-4 h-4 text-teal-600 bg-gray-100 rounded-sm border-gray-300" />
                                                                <label htmlFor="checkbox3" className="ml-2 text-xs text-neutral-500">Me comprometo a cumplir las Obligaciones expuestas en la sección OBLIGACIONES</label>
                                                            </div>
                                                            <ErrorMessage name="checkbox3" component="span" className="mt-2 ml-6 text-xs text-red-600" />
                                                        </div>
                                                        <p className="my-2 text-xs text-neutral-500 pl-6">Conoce las <a onClick={openLink3} href="https://www.colfisiocv.com/obligaciones" target="_blank" className="text-teal-600 underline">Obligaciones de la colegiación</a></p>
                                                    </div>
                                                    <div className="flex flex-col items-start mb-2">
                                                        <div className="flex flex-column items-top">
                                                            <div className="flex items-start">
                                                                <Field name="checkbox4" id="default-checkbox" type="checkbox" className="w-4 h-4 text-teal-600 bg-gray-100 rounded-sm border-gray-300" />
                                                                <label htmlFor="checkbox4" className="ml-2 text-xs text-neutral-500">No me hallo incurso en ningún proceso penal</label>
                                                            </div>
                                                            <ErrorMessage name="checkbox4" component="span" className="mt-2 ml-6 text-xs text-red-600" />
                                                        </div>
                                                        <p className="my-2 text-xs text-neutral-500 pl-6">Que no me hallo incurso en ningún proceso penal que me incapacite para inscribirme en el Ilustre Colegio Oficial de Fisioterapeutas de la Comunidad Valenciana. Que conozco mis derechos, deberes y obligaciones como colegiado/a señalados en los Estatutos del ICOFCV. Que me comprometo a comunicar la baja o cualquier cambio de carácter laboral o personal por escrito, mediante los formularios establecidos para cada caso; así como, atender a los pagos que hubiera pendientes en el momento de producirse estos.
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-col items-start mb-2">
                                                        <div className="flex flex-column items-top">
                                                            <div className="flex items-start">
                                                                <Field name="checkbox5" id="default-checkbox" type="checkbox" className="w-4 h-4 text-teal-600 bg-gray-100 rounded-sm border-gray-300" />
                                                                <label htmlFor="checkbox5" className="ml-2 text-xs text-neutral-500">Estoy al tanto de los Servicios Colegiales expuestos en la sección SERVICIOS COLEGIALES</label>
                                                            </div>
                                                            <ErrorMessage name="checkbox5" component="span" className="mt-2 ml-6 text-xs text-red-600" />
                                                        </div>
                                                        <p className="my-2 text-xs text-neutral-500 pl-6">Conoce los <a onClick={openLink4} className="text-teal-600 underline">Servicios del colegio</a></p>
                                                    </div>
                                                    <div className="flex flex-col items-start mb-2">
                                                        <div className="flex flex-column items-top">
                                                            <div className="flex items-start">
                                                                <Field name="checkbox6" id="default-checkbox" type="checkbox" className="w-4 h-4 text-teal-600 bg-gray-100 rounded-sm border-gray-300" />
                                                                <label htmlFor="checkbox6" className="ml-2 text-xs text-neutral-500">He leído y acepto la legitimación de datos personales</label>
                                                            </div>
                                                            <ErrorMessage name="checkbox6" component="span" className="mt-2 ml-6 text-xs text-red-600" />
                                                        </div>
                                                        <p className="my-2 text-xs text-neutral-500 pl-6">Lea la legitimación de los <a onClick={openLink5} className="text-teal-600 underline">datos personales</a></p>
                                                    </div>
                                                    <div className="flex flex-col items-start mb-2">
                                                        <div className="flex flex-column items-top">
                                                            <div className="flex items-start">
                                                                <Field name="checkbox7" id="default-checkbox" type="checkbox" className="w-4 h-4 text-teal-600 bg-gray-100 rounded-sm border-gray-300" />
                                                                <label htmlFor="checkbox7" className="ml-2 text-xs text-neutral-500">He leído y acepto el envío de correos</label>
                                                            </div>
                                                            <ErrorMessage name="checkbox7" component="span" className="mt-2 ml-6 text-xs text-red-600" />
                                                        </div>
                                                        <p className="my-2 text-xs text-neutral-500 pl-6">Marcando esta casilla autoriza expresamente al ICOFCV al envío periódico a su domicilio por correo convencional o bien por correo electrónico, de diversas comunicaciones. En ocasiones, podrá recibir junto con las mismas publicidad sobre servicios que, a juicio del ICOFCV, puedan resultar de interés para nuestros colegiados.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*footer*/}
                                        <div className="bg-white flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2 ">
                                            <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModal}>Cerrar</button>
                                            <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 outline-none focus:outline-none py-2 rounded" type="submit">Solicitar alta</button>
                                        </div>
                                    </div>
                                </div>
                                <ScrollToError />
                                {isLoading && <Loader />}
                                <ModalConfirmation showModalConfirmation={showModalConfirmation} continueModalConfirmation={continueModalConfirmation} closeModalConfirmation={closeModalConfirmation} isLoading={isLoading} />
                            </Form>
                        </Formik>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}































