import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from '../../validation-form-employment/validationSchema';
import React, { useState, useEffect } from 'react'
import ScrollToErrors from "./ScrollToErrors";
import dateUtils from '../../lib/dateUtils';
import { employmentPush } from '../../api/icofcvAPI';
import { toast } from 'react-toastify';
import Loader from '../spinner/Loader';
import { getCountry, getEmploymentContract } from '../../api/drupalAPI'
import { Country, EmploymentContract } from '@icofcv/common';
import { getProvince } from '../../api/icofcvAPI'
import { Province } from '@icofcv/common';
import { PopUpMessage } from "./PopUpMessage";
import contentUtils from '../../lib/contentUtils';

interface Props {
    showModal: boolean,
    closeModal: () => void,
    updateTable: () => void
}

interface FormValues {
    type: string;
    title: string;
    description: string;
    notes: string;
    publishStartDate: string;
    publishEndDate: string;
    companyName: string;
    regionalRegistrationNumber: string;
    countryId: string;
    provinceId: string;
    provinceName: string;
    city: string;
    address: string;
    postalCode: string;
    mobile: string;
    phone: string;
    email: string;
    web: string;
    contractId: string;
    incorporation?: string;
    schedule?: string;
    scope: string;
    provinceType: string;
    experience: boolean;
    experienceDescription?: string;
}

function validateMobile(value) {
    let errors: any
    if (!value) {
        errors = 'Completa este campo';
    }
    return errors;

}

function validateMobileSpain(value) {
    let errors: any
    if (!value) {
        errors = 'Completa este campo';
    }
    return errors;

}

function validateProvince(value) {
    let errors: any
    if (!value) {
        errors = 'Completa este campo';
    }
    return errors;

}

export const ModalCreateOffer: React.FC<Props> = ({ showModal, closeModal, updateTable }, props) => {

    const [isLoading, setIsLoading] = useState(false)
    const urlLink = "https://colfisiocv.com/sites/default/files/prestacionesservicios.pdf";
    const openLink = () => contentUtils.openExternalUrl(urlLink);

    /////funcionalidad Acordeones
    const [showAccordeon1, setShowAccordeon1] = useState(true)
    const [showAccordeon2, setShowAccordeon2] = useState(false)
    const [showAccordeon3, setShowAccordeon3] = useState(false)
    const [showAccordeon4, setShowAccordeon4] = useState(false)

    const [showModalMessage, setShowModalMessage] = useState(false);
    const openModalMessage = () => setShowModalMessage(true)
    const closeModalMessage = () => setShowModalMessage(false)

    const [employmentContractsList, setEmploymentContractsList] = useState<EmploymentContract[]>([])
    const [countriesList, setCountriesList] = useState<Country[]>([])
    const [provincesList, setProvincesList] = useState<Province[]>([])
    let newValue;

    const dateInput = ({ form, field, ...props }) => {

        return (

            <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                name={field.name}
                defaultValue={new Date().toISOString().split('T')[0]}
                type="date"
                min={new Date().toISOString().split('T')[0]}
                data-accordion={props['data-accordion']}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    newValue = e.currentTarget.value;
                    form.setFieldValue(field.name, newValue)
                }
                }
            />
        )
    }

    const SubDate = (subMonth) => {
        let tgDate = new Date(dateUtils.isoDateToIsoDateTime(newValue));
        tgDate?.setMonth(tgDate?.getMonth() + subMonth)
        return tgDate?.toISOString().split('T')[0];
    }

    const fetchEmploymentContracts = async () => {
        setIsLoading(true);
        getEmploymentContract().then((response) => {
            console.log(response);
            setEmploymentContractsList(response);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.error(error);
            throw error;
        });
    }

    const fetchCountries = async () => {
        setIsLoading(true);
        getCountry().then((response) => {
            console.log(response);
            setCountriesList(response);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.error(error);
            throw error;
        });
    }

    const fetchProvinces = async () => {
        setIsLoading(true);
        getProvince().then((response) => {
            console.log(response);
            setProvincesList(response);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.error(error);
            throw error;
        });
    }

    useEffect(() => {
        fetchCountries()
        fetchProvinces()
        fetchEmploymentContracts()
    }, []);

    const initialValues: FormValues = {
        type: "",
        title: "",
        description: "",
        notes: "",
        publishStartDate: new Date().toISOString().split('T')[0],
        publishEndDate: '',
        companyName: "",
        regionalRegistrationNumber: "",
        countryId: "",
        provinceId: "",
        provinceName: "",
        city: "",
        address: "",
        postalCode: "",
        mobile: "",
        phone: "",
        email: "",
        web: "",
        contractId: "",
        incorporation: "",
        schedule: "",
        scope: "",
        provinceType: "",
        experience: false,
        experienceDescription: ""
    };


    const onSubmit = (values: FormValues) => {
        setIsLoading(true)
        setTimeout(() => {
            console.log(values);
            const data = {
                creationDate: new Date(),
                status: 0,
                type: parseInt(values.type),
                title: values.title,
                description: values.description,
                notes: values.notes,
                publishStartDate: dateUtils.isoDateToIsoDateTime(values.publishStartDate),
                publishEndDate: dateUtils.isoDateToIsoDateTime(values.publishEndDate),
                companyName: values.companyName,
                regionalRegistrationNumber: values.regionalRegistrationNumber,
                countryId: parseInt(values.countryId),
                provinceId: values.provinceId ? parseInt(values.provinceId) : -1,
                provinceName: values.provinceId ? getProvinceNameById(parseInt(values.provinceId)) : values.provinceName,
                city: values.city,
                address: values.address,
                postalCode: values.postalCode,
                mobile: values.mobile,
                phone: values.phone,
                email: values.email,
                web: values.web,
                contractId: parseInt(values.contractId),
                incorporation: values.incorporation,
                schedule: values.schedule,
                scope: parseInt(values.scope),
                provinceType: values.provinceType,
                experience: values.experience,
                experienceDescription: values.experienceDescription
            };
            employmentPush(data)
                .then((response) => {
                    console.log(response)
                    updateTable()
                    closeModal()
                    openModalMessage()
                    toast.success('Empleo creado');
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsLoading(false);
                    throw toast.error('No se ha podido procesar su petición, error:' + error);
                });
        }, 500);
    };

    function getProvinceNameById(id: number): string {
        const province: Province | undefined = provincesList.find(p => p.id === id);
        return province ? province.name : "";
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="fixNotchMarginTop justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none p-0">
                        <div className="relative p-2 md:p-2 w-full max-w-5xl h-screen z-50 md:h-auto">
                            <Formik initialValues={initialValues} onSubmit={(values) => onSubmit(values)} validationSchema={validationSchema} >
                                {props => {

                                    return (

                                        <Form>
                                            {/*content*/}
                                            <div className="relative top-5 md:top-0 bg-white rounded-lg shadow">
                                                {/*header*/}
                                                <div className="flex justify-between items-start px-4 py-3 border-b">
                                                    <h1 className="text-lg font-medium">Crear oferta de trabajo</h1>
                                                    <button onClick={closeModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                                {/*body*/}
                                                <div className="relative px-3 py-3 flex-auto overflow-y-auto modal-body">
                                                    <div id="accordion-collapse" data-accordion="collapse">
                                                        {/* Item */}
                                                        <p className="text-sm font-medium text-teal-600 hover:text-teal-800">El formulario no soporta símbolos o caracteres, por favor evita su uso.</p>
                                                        <h2 id="accordion-collapse-heading-1">
                                                            {showAccordeon1 === false ?
                                                                <button id="accordion-collapse-heading-button-1" onClick={() => setShowAccordeon1(true)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                                    <span>Datos principales</span>
                                                                    <svg data-accordion-icon className="w-6 h-6 rotate-360deg shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                    </svg>
                                                                </button>
                                                                :
                                                                <button id="accordion-collapse-heading-button-1" onClick={() => setShowAccordeon1(false)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                                    <span>Datos principales</span>
                                                                    <svg data-accordion-icon className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                    </svg>
                                                                </button>
                                                            }
                                                        </h2>
                                                        <div id="accordion-collapse-body-1" className={`${showAccordeon1 === false ? 'hidden' : 'hidden:block'} border-b pb-4`} aria-labelledby="accordion-collapse-heading-1">
                                                            <div className="grid md:grid-cols-2 md:gap-4 items-end">
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="type" className="block mb-2 text-xs font-medium text-stone-600">Tipo oferta*</label>
                                                                    <Field name="type" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" >
                                                                        <option value={""}>Seleccionar</option>
                                                                        <option value={"0"}>Bolsa de trabajo (Trabajadores por cuenta ajena)</option>
                                                                        <option value={"1"}>Prestaciones de servicio (Trabajadores por cuenta propia)</option>
                                                                    </Field>
                                                                    <ErrorMessage name="type" component="span" className="mt-2 text-xs text-red-600" />
                                                                </div>
                                                            </div>
                                                            <div className="grid md:grid-cols-1 gap-3 md:gap-4">
                                                                <div className="relative z-0 w-full group">
                                                                    <label htmlFor="title" className="block mb-2 text-xs font-medium text-stone-600">Título*</label>
                                                                    <Field name="title" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                                    <ErrorMessage name="title" component="span" className="mt-2 text-xs text-red-600" />
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="description" className="block mb-2 text-xs font-medium text-stone-600">Descripción*</label>
                                                                    <Field name="description" as="textarea" id="" rows="4" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1" />
                                                                    <ErrorMessage name="description" component="span" className="mt-2 text-xs text-red-600" />
                                                                </div>
                                                            </div>
                                                            <div className="grid md:grid-cols-2 gap-3 md:gap-4 mb-3">
                                                                <div className="relative">
                                                                    <label htmlFor="publishStartDate" className="block mb-2 text-xs font-medium text-stone-600">Fecha publicación*</label>
                                                                    <Field
                                                                        component={dateInput} type="date" min={new Date().toISOString().split('T')[0]} max={SubDate(12)} name="publishStartDate" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" placeholder="Seleccionar fecha" data-accordion="accordion-collapse-body-1" />
                                                                    <ErrorMessage name="publishStartDate" component="span" className="mt-2 text-xs text-red-600" />
                                                                </div>
                                                                <div className="relative">
                                                                    <label htmlFor="publishEndDate" className="block mb-2 text-xs font-medium text-stone-600">Fecha fin publicación*</label>
                                                                    {newValue || props.values.publishStartDate ?
                                                                        <Field type="date" min={newValue || props.values.publishStartDate} max={SubDate(3)} name="publishEndDate" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" placeholder="Seleccionar fecha" data-accordion="accordion-collapse-body-1" />
                                                                        :
                                                                        <Field type="date" disabled min={props.values.publishStartDate} max={SubDate(3)} name="publishEndDate" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" placeholder="Seleccionar fecha" data-accordion="accordion-collapse-body-1" />
                                                                    }
                                                                    <ErrorMessage name="publishEndDate" component="span" className="mt-2 text-xs text-red-600" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <h2 id="accordion-collapse-heading-2">
                                                            {showAccordeon2 === false ?
                                                                <button id="accordion-collapse-heading-button-2" onClick={() => setShowAccordeon2(true)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-2" aria-expanded="true" aria-controls="accordion-collapse-body-2">
                                                                    <span>Datos ofertante*</span>
                                                                    <svg data-accordion-icon className="w-6 h-6 rotate-360deg shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                    </svg>
                                                                </button>
                                                                :
                                                                <button id="accordion-collapse-heading-button-2" onClick={() => setShowAccordeon2(false)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-2" aria-expanded="true" aria-controls="accordion-collapse-body-2">
                                                                    <span>Datos ofertante*</span>
                                                                    <svg data-accordion-icon className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                    </svg>
                                                                </button>
                                                            }
                                                        </h2>

                                                        <div id="accordion-collapse-body-2" className={`${showAccordeon2 === false ? 'hidden' : 'hidden:block'} border-b pb-4`} aria-labelledby="accordion-collapse-heading-2">
                                                            <div className="grid md:grid-cols-2 md:gap-4 items-end">
                                                                <div className="relative z-0 w-full group mb-2">
                                                                    <label htmlFor="companyName" className="block mb-2 text-xs font-medium text-stone-600">Nombre de empresa*</label>
                                                                    <Field name="companyName" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                    <ErrorMessage name="companyName" component="span" className="mt-2 text-xs text-red-600" />

                                                                </div>
                                                                <div className="relative z-0 w-full group mb-2">
                                                                    <label htmlFor="regionalRegistrationNumber" className="block mb-2 text-xs font-medium text-stone-600">Nº de Autorización Sanitaria de Centros, Servicios y Establecimientos Sanitarios / Código Autonómico de Autorización del Centro (Nº Registro Sanitario)*</label>
                                                                    <Field name="regionalRegistrationNumber" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                    <ErrorMessage name="regionalRegistrationNumber" component="span" className="mt-2 text-xs text-red-600" />

                                                                </div>
                                                            </div>

                                                            <h2 className="text-neutral-800 font-medium my-3 border-b border-neutral-300">Dirección</h2>
                                                            <div className="grid md:grid-cols-2 md:gap-4">
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="countryId" className="block mb-2 text-xs font-medium text-stone-600">País*</label>
                                                                    <Field name="countryId" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2">
                                                                        <option value={""}>Seleccionar</option>
                                                                        {countriesList.map((countrie) => (
                                                                            <option value={countrie.id}>{countrie.name}
                                                                            </option>

                                                                        ))}
                                                                    </Field>
                                                                    <ErrorMessage name="countryId" component="span" className="mt-2 text-xs text-red-600" />
                                                                </div>

                                                                {props.values.countryId.toString() === "28" ?
                                                                    <div className="relative z-0 w-full group mb-3">
                                                                        <label htmlFor="provinceId" className="block mb-2 text-xs font-medium text-stone-600">Provincia*</label>
                                                                        <Field name="provinceId" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2">
                                                                            <option value={""}>Seleccionar</option>
                                                                            {provincesList.map((province) => (
                                                                                <option value={province.id}>{province.name}</option>

                                                                            ))}
                                                                        </Field>
                                                                        <ErrorMessage name="provinceId" component="span" className="mt-2 text-xs text-red-600" />

                                                                    </div>
                                                                    :
                                                                    <div className="relative z-0 w-full group mb-3">
                                                                        <label htmlFor="provinceName" className="block mb-2 text-xs font-medium text-stone-600">Provincia*</label>
                                                                        <Field name="provinceName" validate={validateProvince} type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                        <ErrorMessage name="provinceName" component="span" className="mt-2 text-xs text-red-600" />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="relative z-0 w-full group mb-3">
                                                                <label htmlFor="address" className="block mb-2 text-xs font-medium text-stone-600">Dirección*</label>
                                                                <Field name="address" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                <ErrorMessage name="address" component="span" className="mt-2 text-xs text-red-600" />
                                                                <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                            </div>
                                                            <div className="grid md:grid-cols-2 md:gap-4">
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="postalCode" className="block mb-2 text-xs font-medium text-stone-600">Código postal*</label>
                                                                    <Field name="postalCode" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                    <ErrorMessage name="postalCode" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="city" className="block mb-2 text-xs font-medium text-stone-600">Ciudad*</label>
                                                                    <Field name="city" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                    <ErrorMessage name="city" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-neutral-300 w-full my-3"></div>
                                                            <div className="grid md:grid-cols-2 md:gap-4">
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="mobile" className="block mb-2 text-xs font-medium text-stone-600">Teléfono móvil*</label>
                                                                    {props.values.countryId.toString() === "28" ?

                                                                        <>
                                                                            <Field name="mobile" validate={validateMobileSpain} type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                            <ErrorMessage name="mobile" component="span" className="mt-2 text-xs text-red-600" />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Field name="mobile" validate={validateMobile} type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                        </>
                                                                    }
                                                                </div>

                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="phone" className="block mb-2 text-xs font-medium text-stone-600">Teléfono fijo</label>
                                                                    <Field name="phone" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                    <ErrorMessage name="phone" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="web" className="block mb-2 text-xs font-medium text-stone-600">Web url</label>
                                                                    <Field name="web" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    <ErrorMessage name="web" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="email" className="block mb-2 text-xs font-medium text-stone-600">Email*</label>
                                                                    <Field name="email" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-2" />
                                                                    <ErrorMessage name="email" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <h2 id="accordion-collapse-heading-3">
                                                            {showAccordeon3 === false ?
                                                                <button id="accordion-collapse-heading-button-3" onClick={() => setShowAccordeon3(true)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-3" aria-expanded="true" aria-controls="accordion-collapse-body-3">
                                                                    <span>Información</span>
                                                                    <svg data-accordion-icon className="w-6 h-6 rotate-360deg shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                    </svg>
                                                                </button>
                                                                :
                                                                <button id="accordion-collapse-heading-button-3" onClick={() => setShowAccordeon3(false)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-3" aria-expanded="true" aria-controls="accordion-collapse-body-3">
                                                                    <span>Información</span>
                                                                    <svg data-accordion-icon className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                    </svg>
                                                                </button>
                                                            }
                                                        </h2>
                                                        <div id="accordion-collapse-body-3" className={`${showAccordeon3 === false ? 'hidden' : 'hidden:block'} border-b pb-4`} aria-labelledby="accordion-collapse-heading-3">
                                                            <div className="relative z-0 w-full group mb-3">
                                                                <label htmlFor="contractId" className="block mb-2 text-xs font-medium text-stone-600">Modelo de contrato*</label>
                                                                <Field name="contractId" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-3">
                                                                    <option value={""}>Seleccionar</option>
                                                                    {employmentContractsList.map((employmentContract) => (
                                                                        <option value={employmentContract.id}>{employmentContract.name}</option>
                                                                    ))}
                                                                </Field>
                                                                <ErrorMessage name="contractId" component="span" className="mt-2 text-xs text-red-600" />
                                                                <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                            </div>

                                                            <div className="grid md:grid-cols-2 md:gap-4">
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="incorporation" className="block mb-2 text-xs font-medium text-stone-600">Plazo de incorporación</label>
                                                                    <Field name="incorporation" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    <ErrorMessage name="incorporation" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="schedule" className="block mb-2 text-xs font-medium text-stone-600">Horario</label>
                                                                    <Field name="schedule" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    <ErrorMessage name="schedule" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <h2 id="accordion-collapse-heading-4">
                                                            {showAccordeon4 === false ?
                                                                <button id="accordion-collapse-heading-button-4" onClick={() => setShowAccordeon4(true)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-" aria-expanded="true" aria-controls="accordion-collapse-body-4">
                                                                    <span>Perfil*</span>
                                                                    <svg data-accordion-icon className="w-6 h-6 rotate-360deg shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                    </svg>
                                                                </button>
                                                                :
                                                                <button id="accordion-collapse-heading-button-4" onClick={() => setShowAccordeon4(false)} type="button" className="flex items-center justify-between w-full py-2 text-sm font-medium mb-2 text-left" data-accordion-target="#accordion-collapse-body-" aria-expanded="true" aria-controls="accordion-collapse-body-4">
                                                                    <span>Perfil*</span>
                                                                    <svg data-accordion-icon className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                    </svg>
                                                                </button>
                                                            }
                                                        </h2>

                                                        <div id="accordion-collapse-body-4" className={`${showAccordeon4 === false ? 'hidden' : 'hidden:block'} border-b pb-4`} aria-labelledby="accordion-collapse-heading-4">
                                                            <div className="grid md:grid-cols-2 md:gap-4">
                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="scope" className="block mb-2 text-xs font-medium text-stone-600">Ámbito*</label>
                                                                    <Field name="scope" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-4">
                                                                        <option value={""}>Seleccionar</option>
                                                                        <option value={"0"}>Nacional</option>
                                                                        <option value={"1"}>Internacional</option>
                                                                        <option value={"2"}>No definido</option>
                                                                    </Field>
                                                                    <ErrorMessage name="scope" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>

                                                                <div className="relative z-0 w-full group mb-3">
                                                                    <label htmlFor="provinceType" className="block mb-2 text-xs font-medium text-stone-600">Provincia*</label>
                                                                    <Field name="provinceType" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-4">
                                                                        <option value={""}>Seleccionar</option>
                                                                        <option value={"V"}>Valencia</option>
                                                                        <option value={"A"}>Alicante</option>
                                                                        <option value={"C"}>Castellón</option>
                                                                        <option value={"U"}>Comunidad Valenciana</option>
                                                                        <option value={"O"}>Otras provincias</option>
                                                                        <option value={"E"}>Extranjero</option>
                                                                    </Field>
                                                                    <ErrorMessage name="provinceType" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                            </div>

                                                            <div className="border-t pt-4">
                                                                <div className="flex flex-col items-start mb-3">
                                                                    <div className="flex flex-column items-top">
                                                                        <div className="flex items-start">
                                                                            <Field name="experience" id="default-checkbox" type="checkbox" className="w-4 h-4 text-teal-600 focus:ring-teal-600 bg-gray-100 rounded-sm border-gray-300" />
                                                                            <label htmlFor="experience" className="ml-2 text-xs text-neutral-500">Experiencia previa</label>
                                                                        </div>
                                                                        <ErrorMessage name="experience" component="span" className="mt-2 ml-6 text-xs text-red-600" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="relative z-0 w-full group mb-2">
                                                                <label htmlFor="experienceDescription" className="block mb-2 text-xs font-medium text-stone-600">Especificar experiencia previa</label>
                                                                <Field name="experienceDescription" as="textarea" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                <ErrorMessage name="experienceDescription" component="span" className="mt-2 text-xs text-red-600" />
                                                                <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className="relative z-0 w-full group mb-3">
                                                                <label htmlFor="notes" className="block mb-2 text-xs mt-3 font-medium text-stone-600">Observaciones para el colegio</label>
                                                                <Field name="notes" as="textarea" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                <ErrorMessage name="notes" component="span" className="mt-2 text-xs text-red-600" />
                                                            </div>
                                                            <div className="relative z-0 w-full group mb-3">
                                                                <div className="flex items-center gap-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-external-link stroke-teal-600 w-6 h-6" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                                                                        <line x1="10" y1="14" x2="20" y2="4" />
                                                                        <polyline points="15 4 20 4 20 9" />
                                                                    </svg>
                                                                    <a className="my-3 text-sm text-teal-600 hover:text-teal-800 underline" onClick={openLink}>INFORMACIÓN ASESORÍA LABORAL SOBRE DIFERENTES FORMAS DE LA PRESTACIÓN DE SERVICIOS</a>
                                                                </div>
                                                            </div>
                                                            <div className="relative z-0 w-full group mb-3">
                                                                <div className="flex items-center">
                                                                    <input type="checkbox" className="h-4 w-4 rounded-sm border-gray-300 text-teal-600 focus:ring-teal-600" required />
                                                                    <label className="ml-2 block text-sm text-gray-900">He leído la información sobre las diferentes formas de prestación de servicios *</label>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="text-sm text-teal-800 text-left mt-2">
                                                                    El ofertante declara de manera responsable la veracidad de la información y del cumplimiento de todas las obligaciones administrativas (fiscales, laborales, seguridad social, sanitarias, etc.) de su oferta.
                                                                    El ICOFCV no se hace responsable de la veracidad de la información y del cumplimiento de todas las obligaciones administrativas del ofertante al ser un mero transmisor de la información aportada por el mismo, reservándose las acciones que pudieran corresponderle por la inexactitud de las mismas.
                                                                </p>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*footer*/}
                                                <div className="bg-white flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b-lg gap-2 ">
                                                    <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModal}>Cancelar</button>
                                                    <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 outline-none focus:outline-none py-2 rounded" type="submit">Aceptar</button>
                                                </div>
                                            </div>
                                            <ScrollToErrors />
                                            {isLoading && <Loader />}
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <PopUpMessage showModalMessage={showModalMessage} closeModalMessage={closeModalMessage} />
        </>
    )
}
