const statusTypeId = (typeId) => {

   return (
      <>
         {typeId === 1 ?
            <td data-label="description" className="text-xs text-blue-800">Cursos del ICOFCV</td>
            : null}

         {typeId === 2 ?
            <td data-label="description" className="text-xs text-blue-800">Cursos Externos</td>
            : null}

         {typeId === 3 ?
            <td data-label="description" className="text-xs text-blue-800">Seminarios del ICOFCV</td>
            : null}
         {typeId === 4 ?
            <td data-label="description" className="text-xs text-blue-800">Curso Congreso</td>
            : null}
         {typeId === 5 ?
            <td data-label="description" className="text-xs text-blue-800">Oficial</td>
            : null}
         {typeId === 6 ?
            <td data-label="description" className="text-xs text-blue-800">Otra Formación</td>
            : null}
         {typeId === 7 ?
            <td data-label="description" className="text-xs text-blue-800">Título Propio</td>
            : null}
         {typeId === 8 ?
            <td data-label="description" className="text-xs text-blue-800">Cursos de Otros Colegios</td>
            : null}
      </>
   )
}

export default statusTypeId